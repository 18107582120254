.vision_main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 10%;
  padding-top: 10%;
  position: relative;
}
.paragraph_vision {
  border-left: 5px solid rgba(148, 2, 2, 0.2);
  width: 55%;
  line-height: 29px;
  font-weight: 500;
  font-size: 18px;
  color: black;
  padding: 2%;
}
.heading_vision {
  color: #9e0000;
  font-size: 31px;
  font-weight: 800;
  padding-bottom: 1%;
}

.bubble_1 {
  position: absolute;
  top: 150px;
  right: 0;
  height: 300px;
  pointer-events: none;
}
@media (max-width: 760px) {
  .vision_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10%;
  }
  .heading_vision {
    color: #9e0000;
    font-size: 31px;
    font-weight: 800;
    justify-content: center;
    width: 100%;
    padding-bottom: 1%;
  }
  .paragraph_vision {
    border-left: 5px solid rgba(148, 2, 2, 0.2);
    width: 100%;
    line-height: 29px;
    font-weight: 500;
    font-size: 18px;
    color: black;
    padding: 2%;
  }
}
@media (max-width: 560px) {
  .vision_main {
    padding-top: 20%;
  }
}
@media (max-width: 320px) {
  .bubble_1 {
    top: 80px;
    height: 250px;
  }
}
