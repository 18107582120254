.project-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 10px;
  min-height: 144.13px;
  max-height: 144.13px;
  cursor: pointer;
  border-radius: 10px;
}
.project-card__title {
  font-weight: 800;
  text-align: center;
  font-size: 27px;
  height: 50%;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 42px;
  text-transform: uppercase;
}
.project-card__lead {
  font-weight: 400;
  font-size: 22px;
  text-transform: uppercase;
  line-height: 34px;
}
.project-carousel {
  display: flex;
  top: 0;
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  justify-content: space-between;
  align-items: center;
  background: url("../../assets/banner.svg"),
    linear-gradient(180deg, #ffffff 0%, #ffdfdf 100%) no-repeat;
  background-size: cover;
}
.left_arrow {
  padding-left: 2%;
  padding-right: 3%;
  cursor: pointer;
}
.right_arrow {
  padding-left: 3%;
  padding-right: 2%;
  cursor: pointer;
}
.carousel_arrow {
  pointer-events: none;
}
