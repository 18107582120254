.about_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3%;
  position: relative;
  padding-bottom: 3%;
}
.about_container {
  display: flex;
  background-color: #9e0000;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding: 2%;
}
.img_vision {
  width: 100%;
  height: 100%;
}
.paragraph_about {
  line-height: 29px;
  font-weight: 500;
  font-size: 18px;
  color: #fff;

  padding-top: 2%;
  padding-bottom: 3%;
  padding-left: 3%;
  padding-right: 3%;
}
.heading_about {
  color: #fff;
  font-size: 31px;
  border-bottom: 5px solid rgba(177, 81, 81, 1);
  font-weight: 800;
}

.bubble_3 {
  position: absolute;
  bottom: -50px;
  right: 0;
  z-index: -1;
  height: 300px;
  pointer-events: none;
}

@media (max-width: 760px) {
  .about_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 7%;
  }
  .about_container {
    display: flex;
    background-color: #9e0000;
    border-radius: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2%;
  }
  .bubble_3 {
    display: none;
  }
}
