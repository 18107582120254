.developer_details_container {
  padding-bottom: 5%;
}

.developer_details_navbar {
  background: rgba(148, 2, 2, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 2%;
  padding-right: 5%;
  padding-top: 2%;
  flex-wrap: nowrap;
}
.developer_details_navbar_1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 20%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 2%;
  align-items: center;
  flex-wrap: nowrap;
}
.developer_image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.developer_details {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-wrap: nowrap;
}
.developer_details_header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.developer_icons {
  justify-content: flex-end;
  column-gap: 15px;
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 60px;
  width: 30px;
  flex-wrap: nowrap;
}
.developer_name {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 55px;
  color: #9e0000;
}
.developer_bio {
  font-style: normal;
  font-weight: 200;
  padding-top: 15px;
  font-size: 18px;
  white-space: pre-wrap;
  color: #000000;
}

.developer_class {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  color: #9e0000;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 10px;
}
.developer_class img {
  width: 30px;
}
.developer_details_body {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: flex-start;
  column-gap: 2rem;
  align-items: flex-start;
  padding-right: 5%;
}
.developer_details_body_left {
  padding-top: 9%;
  width: 30vw;
}
.developer_details_body_right {
  display: flex;
  width: 60vw;
  padding-top: 2rem;
  row-gap: 1rem;
  flex-direction: column;
  flex-wrap: wrap;
}
.developer_details_body_right_header {
  font-style: normal;
  font-weight: 700;
  font-size: 31px;
  line-height: 42px;

  color: #9e0000;
}
.developer_details_body_right_section {
  padding: 1rem;
}
.developer_details_body_right_section_1 {
  padding: 1rem;
}
.developer_details_body_right_content {
  font-style: normal;
  font-weight: 400;
  padding-top: 3%;
  font-size: 21px;
  line-height: 29px;
  /* identical to box height */

  color: #000000;
}
.developer_details_body_right_content_projects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
}
.developer_details_body_right_content_project {
  width: 203px;
  height: max-content;
  display: flex;
  flex-direction: column;
  background: #9e0000;
  cursor: pointer;
  border-radius: 10px;
  flex-wrap: wrap;
  padding-bottom: 10px;
  align-items: center;
  justify-content: space-between;
}
.developer_details_body_right_content_project img {
  width: 203px;
  height: 110px;
  border-radius: 10px;
}
.developer_details_body_right_content_project_title {
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
  padding-top: 10px;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}
.developer_details_body_right_content_project_lead {
  font-style: normal;
  font-weight: 600;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  color: #ffffff;
}
.developer_details_footer {
  width: 250px;
  height: 56px;
  background: linear-gradient(90deg, #ff512f 0%, #dd2476 100%);
  box-shadow: inset 0px 4px 4px #f48f8f;
  border-radius: 20px;
  display: flex;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  column-gap: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #ffffff;
}
.developer_details_footer_container {
  display: flex;
  justify-content: flex-end;
  padding-right: 5%;
  padding-top: 3%;
}
@media (max-width: 1000px) {
  .developer_details_body {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-right: 0%;
  }
  .developer_details_body_right
  {
    width: 80vw;
  }
  .developer_details_body_right_section {
    background-color: rgba(148, 2, 2, 0.1);
    border-radius: 10px;
    padding: 2rem;
  }
  .developer_details_body_right_section_1 {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .developer_details_body_right_content_projects {
    justify-content: center;
  }
  .developer_details_navbar {
    flex-direction: column;
    justify-content: center;
  }
  .developer_details_navbar_1 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .developer_details_body_left {
    width: 50vw;
  }
}
@media (max-width: 1000px) {
  .developer_details_header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .developer_icons {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
  }
  .developer_class {
    justify-content: center;
  }
  .developer_bio {
    text-align: center;
    justify-content: center;
  }
  .developer_details_footer_container {
    justify-content: center;
  }
  .developer_details_body_left {
    width: 90vw;
  }
}
