.NavbarItems {
  display: flex;
  background-color: #f4e6e6;
  height: 49px;
  font-weight: 600;
  position: fixed;
  z-index: 20;
  font-size: 21px;
  padding-left: 1%;
  margin-top: -5px;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
}

.NavMenu {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 30px;
  text-align: center;
  height: 100%;
}

.NavLinks {
  text-decoration: none;
  color: #9e0000;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  width: 150px;
  transition: background-color 0.3s, font-weight 0.3s, border-bottom 0.3s;
}

.NavLinks:hover,
.NavLinksActive{
  color: #9e0000;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  background-color: #ffcfcf;
  /*font-weight: 800;*/
  border-bottom: 3px solid #9e0000;
}


/*.NavLinks:nth-child(2) {
  padding-left: 35px;
  padding-right: 35px; 
}*/


/*.NavLinksActive {
  color: #9e0000;
  text-decoration: none;
  background-color: #ffcfcf;
  font-weight: 800;
  border-bottom: 3px solid #9e0000;
  padding-right: 35px;
}*/

@media (max-width: 865px) {
  .NavbarItems {
    display: flex;
    z-index: 20;
    font-size: 21px;
    padding-left: 1%;
    margin-top: 40px;
    justify-content: space-evenly;
    align-items: stretch;
    width: 100%;
  }
  .NavMenu {
    grid-gap: 20px;
  }
}
@media (max-width: 480px) {
  .NavbarItems {
    font-size: 18px;
  }
  .NavLinks {
    padding-left: 2px;
    padding-right: 2px;
  }
}
@media (max-width: 367px) {
  .NavbarItems {
    font-size: 15px;
    padding-left: 0%;
  }
  .NavLinks {
    padding-left: 2px;
    padding-right: 2px;
  }
}
