:root {
  --primary-font: 20px;
  --secondary-font: 18px;
  --primary-line: 30px;
  --secondary-line: 28px;
}

.description__container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.description__title {
  font-style: normal;
  font-weight: 800;
  font-size: 31px;
  text-align: center;
  flex-wrap: nowrap;
  line-height: 42px;
  color: #9e0000;
}
.description__content {
  font-style: normal;
  font-weight: 400;
  white-space: pre-wrap;
  padding-top: 30px;
  font-size: var(--primary-font);
  line-height: var(--primary-line);
  text-align: justify;

  color: #263238;
}
.description__other {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.description__other-team {
  font-style: normal;
  padding-top: 30px;
  font-weight: 800;
  font-size: 25px;
  line-height: 34px;
  color: #9e0000;
}
.description__other-reference {
  font-style: normal;
  padding-top: 30px;
  font-weight: 800;
  font-size: 25px;
  line-height: 34px;
  color: #9e0000;
}
.description__other-members {
  display: flex;
  flex-direction: row;
  padding-top: 30px;
  column-gap: 30px;
  row-gap: 5px;
  flex-wrap: wrap;
}
.description__tag_phone {
  display: block;
  font-style: normal;
  padding-top: 3rem;
  font-weight: 400;
  font-size: var(--primary-font);
  line-height: var(--primary-line);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: nowrap;
  color: #263238;
}
.description__tag_phone_mobile {
  display: none;
}
.description__other-member {
  font-style: normal;
  font-weight: 400;
  font-size: var(--primary-font);
  flex-wrap: nowrap;
  line-height: var(--primary-line);

  color: #263238;
  justify-content: flex-start;
  flex-wrap: wrap;
  display: flex;
}
.description__other-references a {
  font-style: normal;
  font-weight: 400;
  font-size: var(--primary-font);
  line-height: var(--primary-line);
  overflow-wrap: anywhere;

  color: #263238;
}
.description__other-references {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 5px;
  padding-top: 30px;
}
.description__tag {
  padding-top: 2%;
  font-style: normal;
  font-weight: 400;
  font-size: var(--primary-font);
  line-height: var(--primary-line);
  column-gap: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #263238;
}
.description__other-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 10px;
}
.description__other-tag {
  padding-top: 2%;
  pointer-events: none;
}

.invite-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #9e0000;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
}

.leave-button:hover {
  background-color: darkred;
  color: white;
  text-decoration: none;
}

.leave-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #9e0000;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
}

.invite-button:hover {
  background-color: darkred;
  color: white;
  text-decoration: none;
}

@media (max-width: 650px) {
  .description__other-members {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    flex-wrap: wrap;
  }

  .description__content {
    font-size: var(--secondary-font);
    line-height: var(--secondary-line);
  }
  .description__other-member {
    font-size: var(--secondary-font);
    line-height: var(--secondary-line);
  }
  .description__other-references a {
    font-size: var(--secondary-font);
    line-height: var(--secondary-line);
  }
  .description__tag {
    font-size: var(--secondary-font);
    line-height: var(--secondary-line);
  }

  .description__other-tags {
    padding-top: 5%;
  }
  .description__tag_phone {
    display: none;
  }
  .description__tag_phone_mobile {
    display: block;
  }
}

@media (max-width: 400px) {
  .description__title {

    font-style: normal;
    font-weight: 700;
    font-size: 27px;
    flex-wrap: nowrap;
    line-height: 38px;
    color: #9e0000;
  }
}
.description-container__controls {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-top: 20px;
  align-items: center;
  padding-bottom: 10px;
  justify-content: flex-end;
  gap: 20px;
}
.description-details__headcontrols {
  gap: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  right: 0;
  padding-right: 5%;
  padding-left: 3%;
  position: absolute;
}
@media (max-width: 795px) {
  .description-details__headcontrols {
    justify-content: center;
    position: relative;
    padding-bottom: 1%;
  }
}
