@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");
body {
  background-color: white !important;
  --primaryColor: #9e0000;
  --secondaryColor: #fff;
  --toastify-color-progress-light: linear-gradient(
    to right,
    #ff8615,
    #ff5f15,
    #ff3815,
    #ff0202
  );
  font-family: "Nunito";
}
html
{
  scroll-behavior: smooth;
  font-family: "Nunito";
}
.App {
  overflow: hidden;
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
  background-color: transparent !important;
}
.nav-item {
  cursor: pointer;
}

// h4 {
//   background-color: var(--primaryColor);
// }
