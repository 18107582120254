.layout__root {
  padding-top: 2.5rem;
  width: 100%;
}
@media (max-width: 865px) {
  .layout__root {
    padding-top: 5.5rem;
    width: 100%;
  }
}
