.animation_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 8rem;
  position: relative;
}

.anime {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  z-index: 1;
  width: 60%;
}

.bubble_8 {
  z-index: -1;
  pointer-events: none;
  position: absolute;
  //position: relative;
  bottom: -10px;
  width: 102%;
  //transform: scale(0.75);
}

.signin_btn {
  background-color: #850000;
  color: white;
  height: 68px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 15px;
  font-weight: 600;
  font-size: 20px;
  border: none;
  outline: none;
  position: absolute;
  cursor: pointer;
  gap: 0.6rem;
  bottom: -20px;
  z-index: 2;
}
.signin_btn:hover {
  background-color: #850000;
}
.signin_btn:focus {
  border: none;
  outline: none;
}
.iedc_logo {
  top: 22%;
  right: 26%;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}

@media (max-width: 1065px) {
  .anime {
    width: 80%;
  }
}
@media (max-width: 560px) {
  .animation_main {
    padding-top: 8rem;
  }
}
@media (max-width: 475px) {
  .animation_main {
    padding-top: 8rem;
  }
}
@media (min-width: 500px) and (max-width: 882px) {
  .signin_btn {
    top: 90%;
    position: absolute;
  }
}
@media (max-width: 882px) {
  .title_2 {
    display: none;
  }
  .iedc_logo {
    display: none;
  }
  .signin_btn {
    bottom: -40px;
    position: absolute;
    padding: 1px;
    font-size: 17px;
    height: 57px;
    width: 250px;
  }
  .google_logo {
    transform: scale(0.7);
  }
}
.title_2 {
  font-weight: 800;
  font-size: 35px;
  color: #850000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  top: 7%;
  left: 30%;
  position: absolute;
  z-index: 2;
}
@media (min-width: 882px) and (max-width: 1352px) {
  .title_2 {
    font-size: 25px;
    top: 9%;
  }

  .iedc_logo {
    right: 24%;
    transform: scale(0.7);
    pointer-events: none;
    position: absolute;
    z-index: 2;
  }
}
.google_logo {
  pointer-events: none;
}
