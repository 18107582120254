.mission_main {
  display: flex;
  flex-direction: column;
  padding-right: 10%;
  align-items: flex-end;
  padding-top: 3%;
  position: relative;
}

.paragraph_mission {
  border-left: 5px solid rgba(148, 2, 2, 0.2);
  width: 55%;
  line-height: 29px;
  font-weight: 500;
  font-size: 18px;
  color: black;
  padding: 2%;
}
.heading_mission {
  color: #9e0000;
  font-size: 31px;
  width: 55%;

  font-weight: 800;
  padding-bottom: 1%;
}
.bubble_2 {
  position: absolute;
  bottom: -200px;
  left: 0;
  height: 300px;
  pointer-events: none;
}
@media (max-width: 760px) {
  .mission_main {
    display: flex;
    flex-direction: column;
    padding: 7%;
  }
  .heading_mission {
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #9e0000;
    font-size: 31px;
    font-weight: 800;
    padding-bottom: 1%;
  }
  .paragraph_mission {
    width: 100%;
    line-height: 29px;
    font-weight: 500;
    font-size: 18px;
    color: black;
    padding: 2%;
  }
  .bubble_2 {
    position: absolute;
    top: 50%;
    left: 0;
    height: 300px;
    pointer-events: none;
  }
}
