.team__data_page_container {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-top: 8rem;
  gap: 3rem;
}
.tech_team_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 3rem;
  padding-top: 8rem;
  padding-right: 3rem;
  row-gap: 2rem;
  align-items: center;
}
.design_team_info {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding-top: 8rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 4rem;
  justify-content: center;
  align-items: center;
}
.team__title {
  color: #9e0000;
  font-size: 31px;
  font-weight: 800;
  padding-bottom: 1%;
}
.members__div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 10rem;
  padding-right: 10rem;
  gap: 5rem;
  justify-content: center;
}
.bubble_2_1 {
  position: absolute;
  top: 100px;
  left: 0;
  z-index: -2;
}
@media (max-width: 650px) {
  .bubble_2_1 {
    top: 60%;
  }
  .members__div {
    padding-left: 0rem;
    padding-right: 0rem;
    gap: 3rem;
  }
}
