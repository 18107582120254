:root {
  --primary-font: 20px;
  --secondary-font: 18px;
  --primary-line: 30px;
  --secondary-line: 28px;
}
.my_profile_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
}
.profile_board {
  background-color: #7a0202;
  padding: 2.5rem;
  display: flex;
  color: #ffff;
  flex-direction: column;
  gap: 2rem;
  min-height: 100vh;
  // background-image: url(../../assets/MyProfBg.svg);
}
.edit__pro_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1.25rem;
  padding: 3rem;
  padding-bottom: 6rem;
}
.pro_image_container {
  display: flex;
  justify-content: center;
}
.edit_board {
  height: 100vh;
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.profile_image {
  width: 15rem;
  height: 15rem;
  border-radius: 15rem;
}
.profile_details_header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.profile_details_header_name {
  font-weight: 700;
  font-size: 2rem;
  padding-top: 2rem;
  text-align: center;
}
.profile_details_container a {
  text-decoration: none;
  color: #ffff;
}
.change-in-edit {
  color: #ffff;
  text-decoration: none;
  font-size: 1.4rem;
  opacity: 0.5;
  font-weight: 400;
}
.profile_details_container a:hover {
  color: #ffff;
  text-decoration: none;
}
.profile_details_container {
  font-size: 1.4rem;
  align-items: center;
}

.edit-profile-text{
  text-decoration: underline;
}

.transparent-button {
  background: none;
  border: none;
  outline: none;
}

.transparent-button:hover {
  background: none !important;
  color: inherit !important;
  text-decoration: underline !important;
}

.transparent-button:focus {
  outline: none !important;
}

.phone_class {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: flex-start;
  padding-top: 1.5rem;
}
.profile_phone {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  // font-weight: 700;
  font-size: 1.25rem;
  line-height: 29px;
}

.edit__pro_box_1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
.reqs_invite_bar {
  display: flex;
  flex-direction: row;
  font-style: normal;

  color: #9e0000;
  justify-content: center;
}
.reqs_invite_bar__requests {
  font-weight: 500;
  font-size: 31px;
  line-height: 44px;
}
.reqs_invite_bar__line {
  font-weight: 500;
  font-size: 31px;
  padding-inline: 1.2rem;
}
.reqs_invite_bar__invite {
  font-weight: 700;
  font-size: 31px;
}
.requests__cards {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.received {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 132px;
  height: 54px;
  cursor: pointer;
  background: #f4e6e6;
  border: 1px solid #9e0000;
  border-bottom-style: none;
  border-radius: 10px 0px 0px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 29px;

  color: #9e0000;
}
.rec_active {
  display: flex;
  width: 132px;
  cursor: pointer;

  height: 54px;
  background: #ebaeae;
  border: 1px solid #9e0000;
  border-radius: 10px 0px 0px 0px;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 29px;
  color: #9e0000;
}
.edit__header {
  display: flex;
  flex-direction: row;
}
.sent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 117px;
  cursor: pointer;

  height: 54px;

  background: #f4e6e6;
  border: 1px solid #9e0000;
  border-bottom-style: none;
  border-radius: 0px 10px 0px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 29px;

  /* identical to box height */

  color: #9e0000;
}
.sent_active {
  display: flex;
  width: 117px;
  cursor: pointer;

  height: 54px;
  background: #ebaeae;
  border: 1px solid #9e0000;
  border-radius: 0px 10px 0px 0px;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;

  color: #9e0000;
  line-height: 29px;
}
.received_bpx_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.received_bpx_header button {
  border: none;

  height: 45px;
  background-color: #f8d0d0;
  color: #9e0000;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
}
.received_bpx_header p {
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  color: #9e0000;
}
.req_profile_img {
  width: 4.75rem;
  height: 4.75rem;

  border: 2px solid #780101;
  border-radius: 2.37rem;
}
.received_sent_box {
  display: flex;
  flex-direction: column;
  border: 1px solid #9e0000;
  border-radius: 0px 10px 10px 10px;
  padding: 1rem;
  margin-top: 0px;
}
.received_btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.5rem;
}
.received_btn_decline {
  width: 9rem;
  height: 3rem;
  background: #ffffff;
  border: 2px solid #9e0000;
  border-radius: 25px;
  color: #9e0000;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  flex-wrap: wrap;
}
.received_btn_accept {
  width: 9rem;
  height: 3rem;
  background: linear-gradient(90deg, #ff512f 0%, #dd2476 100%);
  border: 1px solid #9e0000;
  box-shadow: inset 0px 4px 4px #f48f8f;
  border-radius: 25px;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  flex-wrap: wrap;
}

.edit__pro_abtMe,
.edit__pro_projects,
.edit__pro_skls,
.edit__pro_achvmts,
.edit__pro_dstr,
.edit__pro_state
 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 31px;
  line-height: 42px;
  color: #9e0000;
  padding-top: 1rem;
}
.state-dstr-container{
  display:flex;
  flex-direction: row;
  gap:0.8rem;
  flex:1;
  width:88%;
}
@media screen and (max-width: 768px) {
  .state-dstr-container {
    flex-direction: column; 
    gap: 0.8rem;
    width:100%;
  }
}
.edit__pro_projects {
  padding: 3rem;
  justify-content: center;
}
.developer_details_body_right_content_projects_1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 2rem;
  gap: 30px;
  justify-content: center;
}
.edit__pro_abtMe_bx,
.edit__pro_achvmts_bx {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  padding: 1.2rem;
  font-size: var(--primary-font);
  line-height: var(--primary-line);
  text-align: left;
  color: #171e22;
  justify-content: center;
  background: #ffffff;
  border: 0.4px solid #9e0000;
  border-radius: 10px;
  width: 80%;
  min-height: 3rem;
}

.edit__pro_dstr_bx,
.edit__pro_state_bx{
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: 400;
  padding: 1.2rem;
  font-size: var(--primary-font);
  line-height: var(--primary-line);
  text-align: left;
  color: #171e22;
  justify-content: center;
  background: #ffffff;
  border: 0.4px solid #9e0000;
  border-radius: 10px;
  width: 80%;
  min-height: 3rem;
}
.edit__pro_skls_bx {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  background: #ffffff;
  border: 0.4px solid #9e0000;
  border-radius: 10px;
  padding: 10px;
  font-style: normal;
  font-weight: 400;

  font-size: var(--primary-font);
  line-height: var(--primary-line);
  text-align: left;

  color: #171e22;
  width: 80%;
}
.skill {
  font-size: var(--primary-font);
  line-height: var(--primary-line);

  color: #171e22;
}
@media (min-device-width: 20px) and (max-device-width: 500px) {
  .my_profile_container {
    flex-direction: column;
  }
  .profile_details_header {
    .p {
      font-size: 1.5rem;
    }
  }
  .received_bpx_header button {
    font-size: 0.75rem;
  }
  .received_btn_accept {
    width: 8rem;
    height: 2.5rem;
    font-size: 1rem;
  }
  .received_btn_decline {
    width: 8rem;
    height: 2.5rem;
    font-size: 1rem;
  }
}
@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1100px) {
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 860px) {
  .my_profile_container {
    flex-direction: column;
  }
  .profile_board {
    position: relative;
  }
  .edit__pro_box {
    padding: 0rem;
    padding-top: 2rem;
    padding-bottom: 3rem;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 400px) {
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
}

@media only screen and (device-width: 768px) {
}
