.received_sent_box {
  display: flex;
  flex-direction: column;
  border: 1px solid #9e0000;
  row-gap: 1rem;
  border-radius: 0px 10px 0px 10px;
  padding: 1rem;
  width: 768px;
}
.received_bpx_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.received_bpx_header_para {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;

  color: #9e0000;
}
.received_bpx_header_para_1 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;

  color: #ff1313;
}
.received_bpx_header_para_2 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;

  color: #009e61;
}
.received_bpx_header_para_3 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;

  color: #cf9d12;
}
.req_profile_box {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  justify-content: space-between;
  align-items: center;
}
.req_profile_box_1 {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  justify-content: flex-start;
  align-items: center;
}
.req_profile_details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.5rem;
  font-style: normal;
}
.req_profile_img {
  width: 50px;
  height: 50px;

  border: 2px solid #780101;
  border-radius: 50%;
}
.req_profile_details_h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;

  color: #9e0000;
}

.req_profile_details_p {
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  max-height: 50px;
  overflow: auto;
  color: #9e0000;
}

.received_btns {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.received_btn_decline {
  width: 8rem;
  height: 2.5rem;
  background: #ffffff;
  border: 2px solid #9e0000;
  border-radius: 25px;
  color: #9e0000;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.received_btn_accept {
  width: 8rem;
  height: 2.5rem;
  background: linear-gradient(90deg, #ff512f 0%, #dd2476 100%);
  border: 1px solid #9e0000;
  border-radius: 25px;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.req-box-icon {
  width: 20px;
  height: 20px;
}
.view_project_btn {
  width: 150px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  cursor: pointer;
  color: #9e0000;
  background: #f8d0d0;
  border: 2px solid #f8d0d0;
  border-radius: 10px;
}
@media (max-width: 1254px) {
  .received_sent_box {
    width: 570px;
  }
}
@media (max-width: 1020px) {
  .received_sent_box {
    width: 400px;
  }
}
@media (max-width: 800px) {
  .received_sent_box {
    width: 85vw;
  }
  .received_bpx_header {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 650px) {
  .req_profile_box {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  .received_btns {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 1rem;
    align-items: center;
  }
  .req_profile_box_1 {
    width: 100%;
    justify-content: flex-start;
  }
}
