.team_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 3%;
  position: relative;
}

.heading_team {
  color: #9e0000;
  font-size: 31px;
  font-weight: 800;
  padding-bottom: 1.5rem;
}

.team {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  padding-bottom: 3%;
  column-gap: 5rem;
  row-gap: 3rem;
}
.team_a {
  color: black;
  cursor: pointer;
}
.team_a:hover {
  color: #9e0000;
}
.member img {
  border-radius: 50%;
  object-fit: cover;
  pointer-events: none;
  width: 9rem;
  height: 9rem;
  border: 3px solid #760101;
}

.member .name {
  padding-top: 7%;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #9e0000;
}
.role {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: black;
}
.team_container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.member {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.team__anime {
  width: 30%;
  right: 0;
  position: absolute;
  bottom: 0;
}
.team__anime-1 {
  display: none;
}

.bubble_4 {
  position: absolute;
  top: 0px;
  z-index: -1;
  right: 50;
  height: 300px;
  pointer-events: none;
}
.bubble_5 {
  position: absolute;
  bottom: 0;
  z-index: -1;
  left: 0;
  height: 300px;
  pointer-events: none;
}
@media (max-width: 760px) {
  .team_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 7%;
    padding-bottom: 7%;
  }
  .team__anime {
    display: none;
  }
  .team__anime-1 {
    display: flex;
    width: 80%;
    max-width: 400px;
  }

  .bubble_4 {
    transform: scale(0.7);
    top: -60px;
  }

  .bubble_5 {
    display: none;
  }
}
@media (max-width: 1226px) {
  .team {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    padding: 3%;
    width: 100%;
    column-gap: 5rem;
    row-gap: 3rem;
  }
}
@media only screen and (min-width: 760px) and (max-width: 1226px) {
  .team__anime {
    display: none;
  }
  .team__anime-1 {
    display: flex;
    width: 50%;
    max-width: 450px;
  }
  .bubble_5 {
    display: none;
  }
}
@media (max-width: 320px) {
  .bubble_4 {
    display: none;
  }
}
